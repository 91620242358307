<template>
  <div class="page">
    <sidebarEl/>
    <div class="page-box">
      <headerEl/>
      <div class="page-context">
        <router-view/>
      </div>
    </div>
    <footerEl/>
  </div>
</template>

<script>
import headerEl from "@/components/header";
import footerEl from "@/components/footer";
import sidebarEl from "@/components/sidebar";
export default {
  name: "main",
  components:{
    headerEl,
    footerEl,
    sidebarEl
  },
  props:{

  },
  data() {
    return {

    }
  },
  computed: {},
  created() {

  },
  mounted() {
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.page{
  //background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .page-box{
    width: calc(100% - 333px);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f0f0f0;
    .page-context{
      margin-top: 40px;
      min-height: 100vh;
    }
  }
}
</style>
